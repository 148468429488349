exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-newspost-tsx": () => import("./../../../src/templates/newspost.tsx" /* webpackChunkName: "component---src-templates-newspost-tsx" */),
  "component---src-templates-office-tsx": () => import("./../../../src/templates/office.tsx" /* webpackChunkName: "component---src-templates-office-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-position-tsx": () => import("./../../../src/templates/position.tsx" /* webpackChunkName: "component---src-templates-position-tsx" */)
}

